var generic = generic || {};

$(function() {
  $('.switch-lang-link-hk, .switch-lang-link').on('click', function(event) {
    event.preventDefault();
    return switchLanguageHK($(this).attr('data-mp-lang').toLowerCase());
  });

  function matchChar(fullUrl) {
    var fullUrlParts;
    var appends = '';

    if (fullUrl.indexOf('?') > 0) {
      fullUrlParts = fullUrl.split('?');
      appends = '?' + fullUrlParts[1];
    } else if (fullUrl.indexOf('#') > 0) {
      fullUrlParts = fullUrl.split('#');
      appends = '#' + fullUrlParts[1];
    }

    return appends;
  }

  function switchLanguageHK(lang) {
    var domains = { 'zh_hk': '/', 'zh_HK': '/', 'en_hk': '/en-hk/' };
    var langCookie = (lang === 'zh_hk') ? 'zh_HK' : 'en_HK';

    if (lang !== generic.cookie('LOCALE')) {
      var path = window.location.pathname.replace(/\/en-hk/, '');
      document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      generic.cookie('LOCALE', langCookie, { path: '/' });
      if (path.indexOf('.tmpl') > 0 || path.indexOf('.tmpl') > 0) {
        domains[lang] = '';
      }

      if (path === '/') {
        path = '';
      }
      var site_path = window.location.host + domains[lang] + path;
      site_path = site_path.replace(/\/+/g, '/');
      var url = window.location.protocol + '//' + site_path;
      var full_url = window.location.href;
      var appends = matchChar(full_url);
      if (window.location.href === url + appends) {
        window.location.reload();
      }
      window.location.href = url + appends;
    }
  }

  $(document).ready(function() {
    var path = window.location.pathname.replace(/\/en-hk/, '');
    var full_url = window.location.href;
    var appends = matchChar(window.location.href);
    var cookieLocale = generic.cookie('LOCALE');
    var lang_prefix = (cookieLocale !== undefined && cookieLocale.toLowerCase() === 'en_hk') ? '/en-hk' : '';
    if (path.indexOf('.tmpl') === -1) {
      full_url = window.location.protocol + '//' + window.location.host + lang_prefix + path + appends;
    }
    if (window.location.href !== full_url) {
      window.location.href = full_url;
    }
  });
});
